import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Container, 
  Avatar, 
  Card, 
  CardContent, 
  Grid, 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemText, 
  Divider, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Tabs,
  Tab,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import { 
  Person as PersonIcon, 
  Receipt as BillsIcon,
  Business as CompanyIcon,
  Email as EmailIcon,
  Edit as EditIcon,
  Logout as LogoutIcon,
  MoreVert as MoreIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import BillDetails from './BillDetails';

// Mock user data
const userData = {
  name: 'Suraj Gowda',
  email: 'mysilkcart@gmail.com',
  profileImage: '/suraj.png',
  companyName: 'SilkCart'
};

// Mock API function to fetch bills
const fetchBills = async () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve([
          {
            id: 'INV-2025-001',
            date: '2025-01-15',
            amount: 15000,
            status: 'Paid',
            description: 'Software Application Support - Monthly Subscription',
            period: 'Jan 2025',
            bill: 'https://api.hoineki.com/pdfs/Invoice_Silkcart_Jan2025.pdf'
          },
          {
            id: 'INV-2025-002',
            date: '2025-02-15',
            amount: 20000,
            status: 'Paid',
            description: 'Software Application Support - Monthly Subscription',
            period: 'Feb 2025',
            bill: 'https://api.hoineki.com/pdfs/Invoice_Silkcart_Feb2025.pdf'
           
          },
          {
            id: 'INV-2025-003',
            date: '2025-03-01',
            amount: 20000,
            status: 'Pending',
            description: 'Software Application Support - Monthly Subscription',
            period: 'March 2025',
            bill: 'https://api.hoineki.com/pdfs/Invoice_Silkcart_Mar2025.pdf'
           
          },
        ]);
      }, 100);
    });
  };

const Dashboard = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [profileData, setProfileData] = useState(userData);
  const [tempProfileData, setTempProfileData] = useState(userData);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBill, setSelectedBill] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const loadBills = async () => {
      try {
        const billsData = await fetchBills();
        setBills(billsData);
      } catch (error) {
        console.error('Failed to load bills:', error);
      } finally {
        setLoading(false);
      }
    };

    if (activeTab === 1) {
      loadBills();
    }
  }, [activeTab]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleEditProfile = () => {
    setTempProfileData(profileData);
    setEditMode(true);
  };

  const handleSaveProfile = () => {
    setProfileData(tempProfileData);
    setEditMode(false);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTempProfileData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // In a real app, you would clear auth tokens here
    navigate('/logout');
  };

  const handleViewBill = (bill) => {
    //setSelectedBill(bill);
    window.open(bill.bill, '_blank');

  };

  const handleCloseBillDetails = () => {
    setSelectedBill(null);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" component="h1">
          My Dashboard
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar 
            src={profileData.profileImage} 
            sx={{ width: 40, height: 40, mr: 1 }} 
          />
          <Typography variant="subtitle1" sx={{ mr: 1 }}>
            {profileData.name}
          </Typography>
          <IconButton
            aria-label="more"
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreIcon />
          </IconButton>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleLogout}>
              <LogoutIcon sx={{ mr: 1 }} /> Logout
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <Card sx={{ mb: 4 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Profile" icon={<PersonIcon />} />
          <Tab label="Service Bills" icon={<BillsIcon />} />
        </Tabs>
      </Card>

      {activeTab === 0 && (
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h5">Profile Information</Typography>
              <Button 
                startIcon={<EditIcon />} 
                variant="outlined" 
                disabled
                onClick={handleEditProfile}
              >
                Edit Profile
              </Button>
            </Box>

            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Avatar
                    src={profileData.profileImage}
                    sx={{ width: 120, height: 120, mb: 2 }}
                  />
                  <Typography variant="h6">{profileData.name}</Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {profileData.companyName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      primary="Full Name" 
                      secondary={profileData.name} 
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <EmailIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      primary="Email" 
                      secondary={profileData.email} 
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <CompanyIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      primary="Company" 
                      secondary={profileData.companyName} 
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      {activeTab === 1 && (
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Service Bills
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
              Your software application support service invoices
            </Typography>
            
            {loading ? (
              <Typography>Loading bills...</Typography>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Invoice ID</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Period</TableCell>
                      <TableCell align="right">Amount (₹)</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bills.map((bill) => (
                      <TableRow key={bill.id}>
                        <TableCell>{bill.id}</TableCell>
                        <TableCell>{bill.date}</TableCell>
                        <TableCell>{bill.description}</TableCell>
                        <TableCell>{bill.period}</TableCell>
                        <TableCell align="right">{bill.amount.toFixed(2).toLocaleString()}</TableCell>
                        <TableCell>
                          <Box 
                            sx={{
                              display: 'inline-block',
                              px: 1,
                              py: 0.5,
                              borderRadius: 1,
                              backgroundColor: bill.status === 'Paid' ? 'success.light' : 'warning.light',
                              color: bill.status === 'Paid' ? 'success.contrastText' : 'warning.contrastText'
                            }}
                          >
                            {bill.status}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Button 
                            variant="outlined" 
                            size="small"
                            onClick={() => handleViewBill(bill)}
                          >
                            View/Download
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
      )}

      {/* Edit Profile Dialog */}
      <Dialog open={editMode} onClose={handleCancelEdit}>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <TextField
              label="Full Name"
              name="name"
              value={tempProfileData.name}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Email"
              name="email"
              value={tempProfileData.email}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Company Name"
              name="companyName"
              value={tempProfileData.companyName}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Profile Image URL"
              name="profileImage"
              value={tempProfileData.profileImage}
              onChange={handleInputChange}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelEdit}>Cancel</Button>
          <Button onClick={handleSaveProfile} variant="contained">Save</Button>
        </DialogActions>
      </Dialog>

      {/* Bill Details Dialog */}
      {selectedBill && (
        <BillDetails 
          open={!!selectedBill} 
          bill={selectedBill} 
          onClose={handleCloseBillDetails} 
        />
      )}
    </Container>
  );
};

export default Dashboard;