import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MuPb from "../widgets/MuPb";
import { deleteToken } from "../utils/utils";
const Logout = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    logoutUser();
  }, []);
  function logoutUser() {
    setTimeout(() => {
      deleteToken();
      navigate("/login");
    }, 200);
  }
  if (loading) return <MuPb />;
  return <></>;
};

export default Logout;
