import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const APP_TOKEN = "app_token";
export const saveToken = (token) => {
  localStorage.setItem(APP_TOKEN, token);
};
export const getToken = () => {
  return localStorage.getItem(APP_TOKEN);
};
export const deleteToken = () => {
  localStorage.removeItem(APP_TOKEN);
};
export const isLoggedIn = () => {
  return localStorage.getItem(APP_TOKEN) !== null;
};
export const getHeader = () => {
  return {
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${getToken()}`,
    },
  };
};
export const getMultipartHeader = () => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `Bearer ${getToken()}`,
    },
  };
};
export const UserAuth = ({ children}) => {
  return isLoggedIn() ? children : <Navigate to="/login" />;
};

export function toastError(error) {
  toast.error(getErrorResponse(error));
}
export function getErrorResponse(error) {
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  } else {
    return "An error occurred. Please try again.";
  }
}

