import { ThemeProvider } from "@mui/material";
import { Fragment } from "react";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./Home";
import { AppTheme } from "./theme/AppTheme";
import LoginPage from "./auths/LoginPage";
import MyDashboard from "./components/MyDashboard";
import Logout from "./auths/LogOut";
import { UserAuth } from "./utils/utils";
import HoiNekiLanding from "./LandingPage";
const queryClient = new QueryClient();

const App = () => {
  return (
    <ThemeProvider theme={AppTheme}>
      <QueryClientProvider client={queryClient}>
        <Fragment>
          <BrowserRouter>
            <ToastContainer autoClose={1500} />
            <Routes>
            
              <Route path="/" element={<Home/>} />
              <Route path="/home" element={<UserAuth><MyDashboard /></UserAuth>} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logout" element={<Logout/>} />
            </Routes>
          </BrowserRouter>
        </Fragment>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
