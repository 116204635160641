import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Grid
} from '@mui/material';
import { Receipt as InvoiceIcon } from '@mui/icons-material';

const BillDetails = ({ open, bill, onClose }) => {
  if (!bill) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <InvoiceIcon sx={{ mr: 1 }} />
          <Typography variant="h6">Invoice Details</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Invoice ID:</strong> {bill.id}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Date:</strong> {bill.date}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Period:</strong> {bill.period}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="flex-end">
                <Chip
                  label={bill.status}
                  color={bill.status === 'Paid' ? 'success' : 'warning'}
                  size="medium"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Typography variant="h6" gutterBottom>
          Services Provided
        </Typography>
        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell align="right">Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bill.details.services.map((service, index) => (
                <TableRow key={index}>
                  <TableCell>{service.name}</TableCell>
                  <TableCell align="right">₹{service.cost.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box sx={{ width: '50%', maxWidth: 300 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell><strong>Subtotal</strong></TableCell>
                    <TableCell align="right">₹{bill.details.subtotal.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Tax (18%)</strong></TableCell>
                    <TableCell align="right">₹{bill.details.tax.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Total</strong></TableCell>
                    <TableCell align="right">₹{bill.details.total.toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button 
          variant="contained" 
          onClick={onClose}
          color="primary"
        >
          Download PDF
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BillDetails;