import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Grid,
  Link,
  Container,
  Paper,
  CssBaseline,
  useTheme,
  Fade,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINT, APP_BANNER } from "../utils/constants";
import { isLoggedIn, saveToken } from "../utils/utils";
const LoginPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${API_ENDPOINT}user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Login failed");
      }

      const { token } = await response.json();
      saveToken(token); // Save the token (Implement this function as per your need)
      navigate("/home");
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isLoggedIn()) {
      navigate("/home");
    }
  }, []);

  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        minHeight: "100vh",
        py: 4,
        display: "flex", // Add this
        alignItems: "center", // Add this to center vertically
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Grid
        container
        sx={{
          height: "100%",
          borderRadius: 4,
          overflow: "hidden",
          boxShadow: 3,
        }}
      >
        {/* Left Banner */}
        <Grid
          item
          xs={false}
          md={6}
          sx={{
            backgroundImage: `url(${APP_BANNER})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            "&:before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background:
                "linear-gradient(135deg, rgba(25,118,210,0.85) 0%, rgba(33,150,243,0.75) 100%)",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              p: 8,
              color: "white",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Fade in timeout={1000}>
              <Box>
                <Typography
                  variant="h2"
                  component="h1"
                  sx={{
                    fontWeight: 800,
                    mb: 2,
                    textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
                    letterSpacing: 2,
                  }}
                >
                 HoiNeki
                </Typography>
                <Typography variant="h5" sx={{ mb: 4, fontWeight: 300 }}>
                  Manage services/bills etc.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1.1rem", opacity: 0.9 }}
                >
                  Secure Access to Management Portal
                </Typography>
              </Box>
            </Fade>
          </Box>
        </Grid>

        {/* Right Login Form */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={24}
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: theme.palette.background.default,
              borderRadius: 0,
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: 450,
                px: 4,
                py: 6,
              }}
            >
              <Typography
                component="h1"
                variant="h3"
                sx={{
                  mb: 4,
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                  textAlign: "center",
                }}
              >
                Welcome Back
              </Typography>

              <Box component="form" onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Username"
                  variant="outlined"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  autoFocus
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                      fieldset: { borderWidth: 2 },
                    },
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Password"
                  type="password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                      fieldset: { borderWidth: 2 },
                    },
                  }}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{
                    mt: 4,
                    mb: 2,
                    py: 1.5,
                    borderRadius: 2,
                    fontSize: "1.1rem",
                    fontWeight: 600,
                    letterSpacing: 1,
                    transition: "all 0.3s ease",
                    "&:hover": {
                      transform: "translateY(-2px)",
                      boxShadow: 4,
                    },
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={26} sx={{ color: "white" }} />
                  ) : (
                    "Sign In"
                  )}
                </Button>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                  sx={{ mt: 3, opacity: 0.8 }}
                >
                  Need assistance?{" "}
                  <Link
                    href="mailto:r.doleyz1993@gmail.com"
                    color="primary"
                    sx={{ fontWeight: 600 }}
                  >
                    Contact our team
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoginPage;
